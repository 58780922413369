




















import { SfButton, SfSearchBar, SfImage } from "@storefront-ui/vue";
import { defineComponent, useContext } from "@nuxtjs/composition-api";
import { addBasePath } from "~/helpers/addBasePath";
import { useUiState } from "~/composables";

export default defineComponent({
  name: "HudsonSearchBar",
  components: {
    SfSearchBar,
    SfButton,
    SfImage,
  },
  setup() {
    const { toggleSearchPopup } = useUiState();
    const { app } = useContext();
    const { isDesktop, isMobile } = app.$device;
    const handleSearchClick = async () => {
      toggleSearchPopup();
    };

    return {
      addBasePath,
      handleSearchClick,
      isDesktop,
    };
  },
});
